.markdown {
  img {
    max-width: 100%;
    margin: 1rem 0;
  }

  .ant-carousel img {
    margin: 0;
  }
}

.home {
  height: auto;
  min-height: calc(100vh - 133px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.home > div {
  padding: 1rem;
}

.intro {
}

.heading {
  margin: 0;
  font-size: 1.7rem;
}

.greeting {
  color: white;
  margin-right: 0.5rem;
}

.title {
  color: #c17996;
}

.avatar {
  display: block;
  border-radius: 15px;
  width: 100%;
  max-width: 500px;
}

.avatar:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

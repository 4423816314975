.ant-menu-horizontal.style_menu__SA1yu {
  border-bottom: unset;
  color: #fff;
  max-width: 70%;
}

.style_menu__SA1yu > li.style_ant-menu-item__WV1dl > a {
  color: #fff;
}

.ant-layout-footer.style_footer__LueDj {
  display: flex;
  justify-content: center;
  color: #adadad;
}

.page_header__eo6_F {
  background: #161616; }
  .page_header__eo6_F .page_logo__fObCG {
    cursor: pointer; }
    .page_header__eo6_F .page_logo__fObCG:focus {
      outline: none; }
    .page_header__eo6_F .page_logo__fObCG img {
      height: 32px;
      -webkit-filter: invert(100%);
              filter: invert(100%); }
      .page_header__eo6_F .page_logo__fObCG img:focus {
        outline: none; }

.page_page-content__1HTiJ {
  background: #232323;
  color: #adadad; }

.style_home__2Kk_W {
  height: auto;
  min-height: calc(100vh - 133px);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.style_home__2Kk_W > div {
  padding: 1rem;
}

.style_intro__1gI67 {
}

.style_heading__cK9xO {
  margin: 0;
  font-size: 1.7rem;
}

.style_greeting__leUAv {
  color: white;
  margin-right: 0.5rem;
}

.style_title__1NRTN {
  color: #c17996;
}

.style_avatar__2Tum1 {
  display: block;
  border-radius: 15px;
  width: 100%;
  max-width: 500px;
}

.style_avatar__2Tum1:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.style_projects__sqb35 {
  padding: 2rem;
}

.style_heading__eXmMh {
  margin: 0;
}

.style_card-container__27qY- {
  padding: 1rem;
}

.style_custom-card__1YPpG {
  background: #2b2929;
  color: #fff;
  width: 100%;
  cursor: pointer;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.style_custom-card__1YPpG:hover {
  box-shadow: 0 6px 20px 0 rgba(0,0,0,.35);
}

.style_cover__2xOWx {
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.style_card-content__3Wg0h {
  padding: 1.2rem;
}

.style_card-content__3Wg0h .style_title__2VNtH {
  font-size: 1.3rem;
  font-weight: 500;
}

.style_card-content__3Wg0h .style_description__HcVD_ {
  height: 72px;
}

.style_lightbox__whXAf {
  margin: 2rem 0;
}

.style_project__y9cK7 {
  padding: 2rem 0;
}

.markdown img {
  max-width: 100%;
  margin: 1rem 0; }

.markdown .ant-carousel img {
  margin: 0; }

.style_error__3nMg2 {
  display: flex;
  height: inherit;
  justify-content: center;
}
.style_error-code__33Dqk {
  font-size: 6rem;
}

#root {
  min-height: 100vh; }
  #root .ant-layout {
    min-height: inherit; }
  #root .ant-layout-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    align-items: flex-end;
    justify-content: space-between; }
  #root .ant-layout-content {
    padding-top: 64px; }

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light {
  background-color: #161616; }

body, body #root {
  margin: 0;
  padding: 0;
  font-family: 'Exo 2', -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

body #root * {
  outline: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


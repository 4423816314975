#root {
  min-height: 100vh;

  .ant-layout {
    min-height: inherit;
  }
  .ant-layout-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .ant-layout-content {
    padding-top: 64px;
  }
}

.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light {
  background-color: #161616
}
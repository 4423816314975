.header {
  background: #161616;

  .logo {
    cursor: pointer;

    &:focus {
      outline: none;
    }

    img {
      height: 32px;
      filter: invert(100%);

      &:focus {
        outline: none;
      }
    }
  }
}

.page-content {
  background: #232323;
  color: #adadad;
}

.projects {
  padding: 2rem;
}

.heading {
  margin: 0;
}

.card-container {
  padding: 1rem;
}

.custom-card {
  background: #2b2929;
  color: #fff;
  width: 100%;
  cursor: pointer;
  box-shadow: 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}

.custom-card:hover {
  box-shadow: 0 6px 20px 0 rgba(0,0,0,.35);
}

.cover {
  height: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card-content {
  padding: 1.2rem;
}

.card-content .title {
  font-size: 1.3rem;
  font-weight: 500;
}

.card-content .description {
  height: 72px;
}
